import { Container } from "@mui/system"
import { Link } from "gatsby-theme-material-ui"
import i18next from "i18next"
import * as React from "react"
import Layout from "../components/Layout"
import PageHeading from "../components/PageHeading"
import Seo from "../components/Seo"
import Textblock from "../components/Textblock"
import withTrans from "../components/withTrans"
import locales from "../locales"
import theme from "../theme"

const ContactPage = ({ t }) => {
  return (
    <Layout pageName="404" theme={theme}>
      <Container>
        <PageHeading title={t("contact.heading")} />

        <Textblock mt={theme.spacing(6)} title="Feedback & Support">
          Email: {`contact@${locales[i18next.language].siteName}`}
        </Textblock>

        <Textblock
          mt={theme.spacing(6)}
          title={`${locales[i18next.language].siteName} is created by`}
        >
          YAZIO GmbH
          <br />
          Kartäuserstr. 13a
          <br />
          99084 Erfurt
          <br />
          Germany
        </Textblock>
        <Textblock mt={theme.spacing(6)} title="Commercial Register">
          Limited Company
          <br />
          Register Court: Amtsgericht Jena
          <br />
          Commercial Register Nr: HRB 507453
          <br />
          Germany
        </Textblock>
        <Textblock mt={theme.spacing(6)} title="Chief Executive">
          Florian Weissenstein, Sebastian Weber
          <br />
          VAT ID according to § 27a UStG: DE282484511
          <br />
          Responsible for contents according to § 55 Abs. 2 RStV: Florian
          Weissenstein, Sebastian Weber
          <br />
        </Textblock>
        <Textblock mt={theme.spacing(6)} title="Legal Representation">
          Kanzlei LexTM
          <br />
          Dr. Schmitt-Gaedke + Partner
          <br />
          Münchener Straße 7<br />
          60329 Frankfurt am Main
          <br />
          Germany
          <br />
          <br />
          {false && <Link to="/privacy-policy">Privacy Policy</Link>}
        </Textblock>
        <Textblock mt={theme.spacing(6)} title="General Sources">
          USDA National Nutrient Database for Standard Reference (Release 21)
          <br />
          Nutritional information on producer’ websites
          <br />
          Nutritional information given on food and products
          <br />
        </Textblock>
      </Container>
    </Layout>
  )
}

export default withTrans(ContactPage)

export const Head = () => (
  <Seo title={i18next.t("contact.title")} robots="noindex, follow" />
)
